import React from 'react'
import './Contact.css'
import { tomerCV } from '../../files'
// import bg3 from '../../img/bg3.png'

const Contact=()=>{

    return(
        <div className='contact-me-container' id='contact-me'>
            <h1 className='contact-title'>Contact<span> Me</span></h1>
            <div className='contact-me'>
                {/* <img src={bg3} alt='error'/> */}
                <div className='icons'>
                    <div className='section1'>
                        <i className="i location fas fa-map-marker-alt fa-3x"></i>
                        <span className="city">Israel, Rishon Le Zion</span>
                        <br/>
                        <i title="Send email" className="i email far fa-envelope fa-3x email-icon" onClick={()=>{window.location.href = `mailto:${'tomerzaidler@gmail.com'}`}}></i>
                        <span className="email-text">tomerzaidler@gmail.com</span>
                    </div>
                    <div className='section2'>
                        <div style={{display: "flex"}}>
                            <i title="Facebook" className='i facebook fab fa-facebook fa-3x' onClick={()=>{window.open('https://www.facebook.com/tomer.zaidler')}}></i>
                            <i title="Github" className='i github fab fa-github fa-3x' onClick={()=>{window.open('https://github.com/tomerzaidler')}}></i>
                            <i title="Linkedin" className='i linkedin fab fa-linkedin fa-3x' onClick={()=>{window.open('https://www.linkedin.com/in/tomer-zaidler-ab5a91183/')}}></i>
                        </div>
                        <a title="Download CV" className='contact-btn'  href={tomerCV} download><i className="btn-icon fas fa-download fa-lg"></i>Download Cv</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
