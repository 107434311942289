import React, { Component } from 'react'
import './Skills.css'
import css from './icons/css.png'
import html from './icons/html.png'
import javaScript from './icons/javascript.png'
import react from './icons/react.png'
import angular from './icons/angular.png'
import nodejs from './icons/nodejs.png'
import aws from './icons/aws.png'
import postgres from './icons/postgres.png'
import mysql from './icons/mysql.png'
import k8s from './icons/k8s.png'
import ts from './icons/ts.png'
import git from './icons/git.png'
import docker from './icons/docker.png'
import bash from './icons/bash.png'
import jenkins from './icons/jenkins.png'
import mongodb from './icons/mongodb.png'

class Skills extends Component{

    state={icons:[
                [nodejs,'Node.js'],
                [ts,'Typescript'],
                [javaScript,'Javascript'],
                [aws,'Aws'],
                [k8s,'Kubernetes'],
                [docker,'Docker'],
                [jenkins,'Jenkins'],
                [react,'React'],
                [angular,'Angular'],
                [html,'Html'],
                [css,'Css'],
                [postgres,'PostgreSQL'],
                [mysql,'Mysql'],
                [mongodb,'MongoDB'],
                [git,'Git'],
                [bash,'Bash'],
            ]
        }

    renderIcons=()=>{
       return this.state.icons.map((icon,index)=>{
            return(
                <div className='skill-icon-warp' key={index} >
                    <img className='skill-icon' alt='error' src={icon[0]}/>
                    <h1 className='skill-text'>{icon[1]}</h1>
                </div>
            )
       })
    }

    render(){
        return(
            <div className='skills-container' id='skills' data-aos='fade-right'>
                <h1 className='skills-title'><span>My</span> Skills</h1>
                <div className='skill-list'>
                    {this.renderIcons()}
                </div>
            </div>
        )
    }

}

export default Skills;

