import React from 'react'
import './AboutContent.css'

const AboutMeContent=()=>{
    return(
        <div className='about-me-contianer'>
            <p className='about-me-content'>
            As a Senior Software Engineer, I possess exceptional self-learning abilities and
            demonstrate a high level of motivation, responsibility, and reliability.<br/>
            With diverse  experience spanning FinTech, Autonomous Vehicles, and SaaS industries, I have a
            proven track record of designing and developing scalable web applications using
            various technologies.<br/>
            I am deeply passionate about writing clean, maintainable
            code and thrive in collaborative environments that foster teamwork and
            innovation.<br/>
            I take pride in staying updated on emerging technologies and industry best practices, 
            allowing me to integrate cutting-edge elements into my projects.<br/>
            My commitment to continuous learning is not only reflected in my ability to quickly adapt to new technologies but also in my passion for mentoring and sharing knowledge within the team.
            Throughout my career, I have successfully collaborated with cross-functional teams,
            fostering an environment of open communication and collective problem-solving.<br/>
            This collaborative approach has consistently resulted in the delivery of high-quality, scalable software solutions that meet or exceed client expectations.
            </p>
    </div>
    )
}

export default AboutMeContent;