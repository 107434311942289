import React from 'react'
import './Navbar.css'
import { Link, animateScroll as scroll } from "react-scroll";
import { T_small } from '../../icons/index';


const Navbar=()=>{
    const scrollToTop = () => {
      scroll.scrollToTop();
    };

    return(
        <div className='nav-container'>
              <img className='home' src={T_small} alt='error' onClick={scrollToTop}/>
              <div>
                <Link className='link' activeClass="active" to="about-me" spy={true} smooth={true} offset={-300} duration={1000}>About</Link>
              <Link className='link' activeClass="active" to="skills" spy={true} smooth={true} offset={-50} duration={1000}>Skills</Link>
              {/* <Link className='link' activeClass="active" to="resume" spy={true}smooth={true} offset={-100} duration={1000}>Cv</Link> */}
              <Link className='link' activeClass="active" to="contact-me" spy={true} smooth={true} offset={-50} duration={1000}>Contact</Link>
              {/* <Link className='link' ctiveClass="active" to="projects" spy={true} smooth={true} offset={-100} duration={1000}>Projects</Link> */}
              </div>
              
        </div>
    )
}

export default Navbar;