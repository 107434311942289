import React from 'react';
import Navbar from './Navbar/Navbar'
import Intro from './Intro/Intro'
import About from './About/About'
import Skills from './Skills/Skills'
import Contact from './Contact/Contact'
import './app.css';

class App extends React.Component {
    render() {
        return (
            <div>
                <Navbar/>
                <Intro/>
                <About/>
                <Skills/>
                <Contact/>
            </div>
        );
    }
}

export default App;
