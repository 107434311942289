import React from 'react'
import './Intro.css'
import { T_icon } from '../../icons/index';

const Intro=()=>{
    return(
        <div id='intro'>
            <div className='intro-wrapper'>
                <img className='intro-img' src={T_icon} alt='error'/>
                <div className="intro-titles">
                    <h1 className='title'>Tomer Zaidler</h1>
                    <p className='intro-subtitle'>Senior Backend Software Engineer</p>
                </div>
                <div className="intro-titles-mobile">
                    <h1 className=''>Tomer Zaidler</h1>
                    <h3 className='job-title'>Senior Backend Software Engineer</h3>
                </div>
                <div className="box">
                    {[1,2,3,4,5,6,7,8,9,0].map(_ => <div key="_"></div>)}
                </div>
            </div>
        </div>
    )
}

export default Intro;